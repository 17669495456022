import React, { useEffect, useState } from "react";
import "./mainsection.css";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import useContract from "../../hooks/useContracts";
import useInfura from "../../hooks/useinfura";
import logo from '../../assets/bnb-bnb-logo.png';
import ClipLoader from "react-spinners/ClipLoader";
import toast from "react-hot-toast";
import QRCode from "qrcode.react";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const MainSection = () => {
  const [amount, setAmount] = useState(0.01);
  const [receive, setReceive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [color] = useState("#ffffff");
  const [totalTokenIsSold, setTotalTokenIsSold] = useState();
  const [change, setChange] = useState(false);
  const [tokenSymbol, setTokenSymbol] = useState('');
  const { address, isConnected } = useWeb3ModalAccount();
  const { buyTokens, TOKEN_CONTRACT_ADDRESS } = useContract();
  const { getContractData, contractData, loading: infuraLoading, error } = useInfura(); // Fetch contract data and state

  const [tokenRate, setTokenRate] = useState();
  const [paiedToNow, setPaiedToNow] = useState();

  const contractAdd = TOKEN_CONTRACT_ADDRESS;
  const [, setReferral] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    if (ref) {
      setReferral(ref);
    }
  }, []);

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        if (contractData) {
          console.log("Contract Data:", contractData); // Debugging line

          setTokenSymbol('$AIC');
          setTotalTokenIsSold(contractData.tokensSold);
          setPaiedToNow(contractData.weiRaised);
          setTokenRate(Number(contractData.rate));
          setReceive(amount * Number(contractData.rate));
        }
      } catch (error) {
        console.error("Error fetching contract data:", error);
      }
    };
    fetchContractData();
  }, [address, change, contractData, amount]);

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value >= 0.001 && value <= 5) {
      setAmount(value);
    } else if (value < 0.001) {
      setAmount(0.001);
    } else if (value > 5) {
      setAmount(5);
    }
  };

  const handleBuy = async () => {
    setLoading(true);
    try {
      if (amount < 0.001 || amount > 5) {
        toast.error("Amount must be between 0.001 and 5 BNB");
        setLoading(false);
        return;
      }

      const success = await buyTokens(amount, address);

      if (success) {
        toast.success("Transaction successful");
        setChange(!change); // Trigger state change if needed
      } else {
        toast.error("Transaction failed");
      }
    } catch (error) {
      console.error("Error in handleBuy:", error);
      toast.error("Transaction failed");
    }
    setLoading(false);
  };

  const ProgressBar = ({ percentage }) => (
    <div className="custom-progress-container">
      <div className="progress" style={{ width: `${percentage}%` }}></div>
    </div>
  );

  const totalEth = 7502355;
  const percentage = totalEth > 0 ? (paiedToNow / totalEth) * 100 : 0;

  if (infuraLoading) return <p>Loading contract data...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="presale-card-wrapper">
              <div className="presale-card">
                <div className="presale-card-right">
                  <div className="presale-card-title">
                    <p>Presale</p>
                  </div>
                  <div className="sc-iBdnpw kYFWru">
                    <div className="pay-with-content">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button className="pay-with-button">
                          <img src={logo} alt="icon" />
                          Buy with BNB
                        </button>
                        {isConnected && <w3m-button />}
                      </div>
                      <div className="progress-bar-container">
                        <p>
                          Total Purchase: {totalTokenIsSold} $AIC of {1000000000}  $AIC
                        </p>
                        <div className="progress-bar-x">
                          <ProgressBar percentage={percentage} />
                        </div>
                        <p >
                          Exchange Rate: 1 BNB = {tokenRate} $AIC
                        </p>
                      </div>
                    </div>
                    <form action="/" method="post">
                      <div className="presale-item mb-35">
                        <div className="presale-item-inner">
                          <label>Pay Token (BNB)</label>
                          <input
                            type="number"
                            placeholder="0"
                            value={amount}
                            onChange={handleAmountChange}
                            min="0.001"
                            max="5"
                            step="0.001"
                          />
                        </div>
                        <div className="presale-item-inner">
                          <label>Get Token ({tokenSymbol})</label>
                          <input
                            type="number"
                            placeholder="0"
                            value={receive}
                            readOnly
                          />
                        </div>
                      </div>
                    </form>
                    <div className="presale-item-msg"></div>
                    <div variant="pay" className="sc-guDLey hKEsDZ">
                      {isConnected ? (
                        <button
                          className="connect-wallet-btn"
                          onClick={handleBuy}
                          style={{
                            cursor: loading ? "not-allowed" : "pointer",
                          }}
                          disabled={loading || amount < 0.001 || amount > 3}
                        >
                          {loading ? (
                            <ClipLoader
                              color={color}
                              loading={loading}
                              cssOverride={override}
                              size={30}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          ) : (
                            "Buy Token"
                          )}
                        </button>
                      ) : (
                        <div className="pay-directly-container">
                          <w3m-button />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pay-directly-container">
                    <p>Buy by transferring ETH directly to this address:</p>
                    <div className="eth-address">
                      <QRCode value={contractAdd} size={256} />
                      <p className="eth-address-text">{contractAdd}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
