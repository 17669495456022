import { BrowserProvider, Contract, formatUnits, parseUnits, isAddress } from "ethers";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { PRESALE_CONTRACT_ADDRESS, PRESALE_ABI, TOKEN_CONTRACT_ADDRESS } from "../contracts/contract";
import toast from "react-hot-toast";

function useContract() {
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();

  //const INFURA_PROJECT_ID = 'ad3ddcc3825b4d058416f7db30e8c462';

  
  const getProvider = () => new BrowserProvider(walletProvider);

  const getSigner = async (provider) => provider.getSigner();

  const getContract = async (address, abi, signer) => {
    try {
      return new Contract(address, abi, signer);
    } catch (error) {
      console.error('Error initializing contract:', error);
      throw error; // Rethrow the error for higher-level handling
    }
  };

  const buyTokens = async (amount, ref) => {
    try {
      // Log the ref address for debugging
      console.log('Ref address received:', ref);

      // Validate ref address
      if (!ref || ref === "0x0000000000000000000000000000000000000000" || !isAddress(ref)) {
        console.error('Invalid beneficiary address:', ref);
        throw new Error('Invalid beneficiary address');
      }

      console.log(`Buying tokens for address: ${address}, amount: ${amount}, referral: ${ref}`);

      const provider = getProvider();
      const signer = await getSigner(provider);
      const contract = new Contract(PRESALE_CONTRACT_ADDRESS, PRESALE_ABI, signer);

      const transaction = await contract.buyTokens(ref, {
        value: parseUnits(amount.toString(), 18),
      });

      await transaction.wait();
      return true;
    } catch (error) {
      console.error('Error purchasing tokens:', error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        toast.error('Insufficient funds for gas and value. Please add more ETH to your wallet.');
      } 
      return false;
    }
  };

  const getContractData = async () => {
    try {
      const provider = getProvider();
      const signer = await getSigner(provider);
      const contract = await getContract(PRESALE_CONTRACT_ADDRESS, PRESALE_ABI, signer);

      const tokensSold = formatUnits(await contract.tokensSold(), 18);
      //const tokensSold = await contract.tokensSold();
      const rate = await contract.rate();
      const minContribution = formatUnits(await contract.minContribution(), 18);
      const maxContribution = formatUnits(await contract.maxContribution(), 18);
      const isPaused = await contract.isPaused();
      const weiRaised = formatUnits(await contract.weiRaised(), 18);
      const tokensRemaining = formatUnits(await contract.tokensRemaining(), 18);
      const startTime = await contract.startTime();
      const endTime = await contract.endTime();

      return {
        tokensSold,
        rate,
        minContribution,
        maxContribution,
        isPaused,
        weiRaised,
        tokensRemaining,
        startTime,
        endTime
      };
    } catch (error) {
      console.error('Error fetching contract data:', error);
      throw error;
    }
  };

  const token = async () => {
    try {
      const provider = getProvider();
      const signer = await getSigner(provider);
      const contract = await getContract(PRESALE_CONTRACT_ADDRESS, PRESALE_ABI, signer);
      const endTime = await contract.token(); // Adjust this if 'token' is not the correct method
      return endTime;
    } catch (error) {
      console.error('Error fetching token data:', error);
      return null;
    }
  };



  return { buyTokens,  TOKEN_CONTRACT_ADDRESS,token };
}

export default useContract;
