import "./App.css";

import MainSection from "./components/MainSection/MainSection";


import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { Toaster } from "react-hot-toast";

// 1. Get projectId
const projectId = "40a26f9a436518782d325ace4debf54c";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};
const sepolia = {
  chainId: 11155111,
  name: "Sepolia",
  currency: "ETH",
  explorerUrl: "https://sepolia.etherscan.io",
  rpcUrl: "https://rpc.sepolia.org",
};

// 3. Create a metadata object
const metadata = {
  name: "Astroinfant Coin",
  description: "Reach for the Stars, Empower Dreams with Astronaut Coin.",
  url: "https://astroinfant.site", // origin must match your domain & subdomain
  icons: ["https://zever.net/wp-content/uploads/2024/08/img_team_05.jpg"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet, sepolia],
  projectId,
  enableAnalytics: true,
});

function App() {
  return (
    <div className="sc-eDLKkx eTbKOW">
      <MainSection />
      <Toaster />
    </div>
  );
}

export default App;
