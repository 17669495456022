// src/contract/Contract.js

export const TOKEN_ABI = [
    {
    inputs: [ ],
    stateMutability: "nonpayable",
    type: "constructor"
    },
    {
    anonymous: false,
    inputs: [
    {
    indexed: true,
    internalType: "address",
    name: "owner",
    type: "address"
    },
    {
    indexed: true,
    internalType: "address",
    name: "spender",
    type: "address"
    },
    {
    indexed: false,
    internalType: "uint256",
    name: "value",
    type: "uint256"
    }
    ],
    name: "Approval",
    type: "event"
    },
    {
    anonymous: false,
    inputs: [
    {
    indexed: true,
    internalType: "address",
    name: "contractAddress",
    type: "address"
    }
    ],
    name: "AuthorizedContractAdded",
    type: "event"
    },
    {
    anonymous: false,
    inputs: [
    {
    indexed: true,
    internalType: "address",
    name: "contractAddress",
    type: "address"
    }
    ],
    name: "AuthorizedContractRemoved",
    type: "event"
    },
    {
    anonymous: false,
    inputs: [
    {
    indexed: true,
    internalType: "address",
    name: "burner",
    type: "address"
    },
    {
    indexed: false,
    internalType: "uint256",
    name: "value",
    type: "uint256"
    }
    ],
    name: "Burn",
    type: "event"
    },
    {
    anonymous: false,
    inputs: [
    {
    indexed: true,
    internalType: "address",
    name: "previousOwner",
    type: "address"
    },
    {
    indexed: true,
    internalType: "address",
    name: "newOwner",
    type: "address"
    }
    ],
    name: "OwnershipTransferred",
    type: "event"
    },
    {
    anonymous: false,
    inputs: [ ],
    name: "Pause",
    type: "event"
    },
    {
    anonymous: false,
    inputs: [
    {
    indexed: true,
    internalType: "address",
    name: "from",
    type: "address"
    },
    {
    indexed: true,
    internalType: "address",
    name: "to",
    type: "address"
    },
    {
    indexed: false,
    internalType: "uint256",
    name: "value",
    type: "uint256"
    }
    ],
    name: "Transfer",
    type: "event"
    },
    {
    anonymous: false,
    inputs: [ ],
    name: "Unpause",
    type: "event"
    },
    {
    inputs: [ ],
    name: "INITIAL_SUPPLY",
    outputs: [
    {
    internalType: "uint256",
    name: "",
    type: "uint256"
    }
    ],
    stateMutability: "view",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "address",
    name: "contractAddress",
    type: "address"
    }
    ],
    name: "addAuthorizedContract",
    outputs: [ ],
    stateMutability: "nonpayable",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "address",
    name: "owner",
    type: "address"
    },
    {
    internalType: "address",
    name: "spender",
    type: "address"
    }
    ],
    name: "allowance",
    outputs: [
    {
    internalType: "uint256",
    name: "",
    type: "uint256"
    }
    ],
    stateMutability: "view",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "address",
    name: "spender",
    type: "address"
    },
    {
    internalType: "uint256",
    name: "amount",
    type: "uint256"
    }
    ],
    name: "approve",
    outputs: [
    {
    internalType: "bool",
    name: "",
    type: "bool"
    }
    ],
    stateMutability: "nonpayable",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "address",
    name: "account",
    type: "address"
    }
    ],
    name: "balanceOf",
    outputs: [
    {
    internalType: "uint256",
    name: "",
    type: "uint256"
    }
    ],
    stateMutability: "view",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "uint256",
    name: "amount",
    type: "uint256"
    }
    ],
    name: "burn",
    outputs: [
    {
    internalType: "bool",
    name: "",
    type: "bool"
    }
    ],
    stateMutability: "nonpayable",
    type: "function"
    },
    {
    inputs: [ ],
    name: "decimals",
    outputs: [
    {
    internalType: "uint8",
    name: "",
    type: "uint8"
    }
    ],
    stateMutability: "view",
    type: "function"
    },
    {
    inputs: [ ],
    name: "name",
    outputs: [
    {
    internalType: "string",
    name: "",
    type: "string"
    }
    ],
    stateMutability: "view",
    type: "function"
    },
    {
    inputs: [ ],
    name: "pause",
    outputs: [ ],
    stateMutability: "nonpayable",
    type: "function"
    },
    {
    inputs: [ ],
    name: "paused",
    outputs: [
    {
    internalType: "bool",
    name: "",
    type: "bool"
    }
    ],
    stateMutability: "view",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "address",
    name: "contractAddress",
    type: "address"
    }
    ],
    name: "removeAuthorizedContract",
    outputs: [ ],
    stateMutability: "nonpayable",
    type: "function"
    },
    {
    inputs: [ ],
    name: "symbol",
    outputs: [
    {
    internalType: "string",
    name: "",
    type: "string"
    }
    ],
    stateMutability: "view",
    type: "function"
    },
    {
    inputs: [ ],
    name: "totalSupply",
    outputs: [
    {
    internalType: "uint256",
    name: "",
    type: "uint256"
    }
    ],
    stateMutability: "view",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "address",
    name: "recipient",
    type: "address"
    },
    {
    internalType: "uint256",
    name: "amount",
    type: "uint256"
    }
    ],
    name: "transfer",
    outputs: [
    {
    internalType: "bool",
    name: "",
    type: "bool"
    }
    ],
    stateMutability: "nonpayable",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "address",
    name: "sender",
    type: "address"
    },
    {
    internalType: "address",
    name: "recipient",
    type: "address"
    },
    {
    internalType: "uint256",
    name: "amount",
    type: "uint256"
    }
    ],
    name: "transferFrom",
    outputs: [
    {
    internalType: "bool",
    name: "",
    type: "bool"
    }
    ],
    stateMutability: "nonpayable",
    type: "function"
    },
    {
    inputs: [
    {
    internalType: "address",
    name: "newOwner",
    type: "address"
    }
    ],
    name: "transferOwnership",
    outputs: [ ],
    stateMutability: "nonpayable",
    type: "function"
    },
    {
    inputs: [ ],
    name: "unpause",
    outputs: [ ],
    stateMutability: "nonpayable",
    type: "function"
    }
    ];

export const PRESALE_ABI =[
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "newRate",
				"type": "uint256"
			}
		],
		"name": "adjustRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "beneficiary",
				"type": "address"
			}
		],
		"name": "buyTokens",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "newEndTime",
				"type": "uint256"
			}
		],
		"name": "extendSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rate",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			},
			{
				"internalType": "contract IERC20",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_startTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_endTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_minContribution",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxContribution",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "pauseSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "oldRate",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "newRate",
				"type": "uint256"
			}
		],
		"name": "RateChanged",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "resumeSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "oldEndTime",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "newEndTime",
				"type": "uint256"
			}
		],
		"name": "SaleExtended",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "SalePaused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "SaleResumed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "purchaser",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "beneficiary",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "TokensPurchased",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawFunds",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "endTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "isCrowdsaleActive",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "isPaused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxContribution",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minContribution",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokensRemaining",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokensSold",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wallet",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "weiRaised",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

export const PRESALE_CONTRACT_ADDRESS = '0x6C969d20c6709B859A94250AA3242c108ef8e5ab';
export const TOKEN_CONTRACT_ADDRESS ="0xf1f8B2FE890B53ca707817DD82cC6b319CCc8F41";


