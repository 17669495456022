import { Contract, formatUnits, JsonRpcProvider } from "ethers";
import { PRESALE_CONTRACT_ADDRESS, PRESALE_ABI } from "../contracts/contract";
import { useState, useEffect, useCallback, useMemo } from 'react';

function useInfura() {
  const INFURA_PROJECT_ID = '04fbb775e97e46309e517fc69f51ba7c';

  // Memoize the provider for Sepolia network
  const provider = useMemo(() => new JsonRpcProvider(`https://sepolia.infura.io/v3/${INFURA_PROJECT_ID}`), [INFURA_PROJECT_ID]);

  const [contractData, setContractData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getContract = useCallback((address, abi) => {
    try {
      return new Contract(address, abi, provider);
    } catch (error) {
      console.error('Error initializing contract:', error);
      throw error;
    }
  }, [provider]);

  const getContractData = useCallback(async () => {
    setLoading(true);
    try {
      const contract = getContract(PRESALE_CONTRACT_ADDRESS, PRESALE_ABI);

      // Fetch data from the contract
      const tokensSold = formatUnits(await contract.tokensSold(), 18);
      const rate = await contract.rate();
      const minContribution = formatUnits(await contract.minContribution(), 18);
      const maxContribution = formatUnits(await contract.maxContribution(), 18);
      const isPaused = await contract.isPaused();
      const weiRaised = formatUnits(await contract.weiRaised(), 18);
      const tokensRemaining = formatUnits(await contract.tokensRemaining(), 18);
      const startTime = await contract.startTime();
      const endTime = await contract.endTime();

      setContractData({
        tokensSold,
        rate,
        minContribution,
        maxContribution,
        isPaused,
        weiRaised,
        tokensRemaining,
        startTime,
        endTime
      });
    } catch (error) {
      console.error('Error fetching contract data:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [getContract]);

  useEffect(() => {
    getContractData();
  }, [getContractData]);

  return { contractData, loading, error };
}

export default useInfura;
